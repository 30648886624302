import { navigate } from 'gatsby';
import React from 'react';


const allowedDomains = [
    'https://t.me/virtue_poker',
    'https://discord.gg/pu3e95wKMn',
    'https://twitter.com/virtue_poker'
];

class RedirectionPage extends React.Component {
    componentDidMount = () => {
        if (window) {
            const urlParams = new URLSearchParams(window.location.search);
            const redirection = urlParams.get('url');
            // It is important to check if the redirection is allowed to prevent malicious redirections.
            if(allowedDomains.includes(redirection)){
                window.location.href = redirection;
            } else {
                navigate('/');
            }
        }
    }
    render = () => null;
};

export default RedirectionPage;
